import { Link } from "react-router-dom"
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import '../styles/Code.css'
import { useState } from "react"
import { callSupportCodePost } from "../actions/login"

let Code = ({ callSupportCodePost }) => {
    const [code, setCode] = useState('')

    const submit = (e) => {
        e.preventDefault()
        callSupportCodePost(code, _ => window.navigate('/admin'))
    }

    return (
        <div className="container-support-code d-flex align-items-stretch">
            <div className="img d-flex align-items-center shadow-video">

            </div>
            <div className="sdk d-flex flex-column p-4 pt-5">
                <Link to={'/admin/support/login'} className="logo"><img src="/assets/img/logo.png" alt="Espartaco Soluções" /></Link>
                <div className="authfast-sdk mt-5">
                    <div className="box" style={{ width: '100%' }}>
                        <div className="login" style={{ flex: '1 1 0%', maxWidth: '340px' }}>
                            <div className="sign-up">
                                <form onSubmit={submit}>
                                    <div className="form-row">
                                        <div className="col-12 form-group input-group">
                                            <input type="text" placeholder="Digite o código" className="form-control bg-input" style={{ width: 'auto !important' }} onChange={(e) => setCode(e.target.value)} value={code} />
                                        </div>
                                        <div className="col-md-12">
                                            <button type="submit" className="btn btn-primary btn-block btn-lg">Acessar</button>
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <Link to={'/admin/account/login'} className="mt-1"><small>Voltar para o login</small></Link>
                                        </div>
                                        <div className="col-md-12 text-center mt-5">
                                            <a href="https://www.tracktracerx.com" className="d-inline-block"><img src="/assets/img/by.png" alt="GoFind4Me" /></a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            callSupportCodePost
        },
        dispatch
    );


export default connect(mapStateToProps, mapDispatchToProps)(Code)

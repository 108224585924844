import { toast } from "react-toastify";
import Api from '../../../../axios'
import { callLoader } from '../../../../common/actions/app'

let ACTION_SEARCH_KEYWORDS_LIST = {
    type: 'SEARCH_KEYWORDS_LIST',
    payload: {
        rows: [],
        total: 0,
        pagination: [],
    },
};

let ACTION_SEARCH_KEYWORDS_VIEW = {
    type: 'SEARCH_KEYWORDS_VIEW',
    payload: {
        keywords: '',
        additionals: '',
        unwanted: ''
    },
};

let callSearchKeywordsListGet = (groupId, filter = {}, pg = 1) => (dispatch) => {
    dispatch(callLoader(true))
    Api.get(`/user/search/keywords/${groupId}?pg=${pg}`).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        ACTION_SEARCH_KEYWORDS_LIST.payload.rows = data.rows
        ACTION_SEARCH_KEYWORDS_LIST.payload.total = data.total
        ACTION_SEARCH_KEYWORDS_LIST.payload.pagination = data.pagination
        dispatch(ACTION_SEARCH_KEYWORDS_LIST)
    })
}

let callSearchKeywordsViewGet = (groupId, id) => (dispatch) => {
    dispatch(callLoader(true))
    Api.get(`/user/search/keywords/${groupId}/${id}`).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        ACTION_SEARCH_KEYWORDS_VIEW.payload.keywords = data.keywords
        ACTION_SEARCH_KEYWORDS_VIEW.payload.additionals = data.additionals
        ACTION_SEARCH_KEYWORDS_VIEW.payload.unwanted = data.unwanted
        dispatch(ACTION_SEARCH_KEYWORDS_VIEW)
    })
}

let callSearchKeywordsPost = (groupId, data, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.post(`/user/search/keywords/${groupId}`, data).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

let callSearchKeywordsPut = (groupId, id, data, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.put(`/user/search/keywords/${groupId}/${id}`, data).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

let callSearchKeywordsClearView = () => (dispatch) => {
    ACTION_SEARCH_KEYWORDS_VIEW.payload.keywords = ''
    ACTION_SEARCH_KEYWORDS_VIEW.payload.additionals = ''
    ACTION_SEARCH_KEYWORDS_VIEW.payload.unwanted = ''
    dispatch(ACTION_SEARCH_KEYWORDS_VIEW)
}

let callSearchKeywordsDelete = (groupId, id, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.delete(`/user/search/keywords/${groupId}/${id}`).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

export { callSearchKeywordsListGet, callSearchKeywordsViewGet, callSearchKeywordsPost, callSearchKeywordsPut, callSearchKeywordsDelete, callSearchKeywordsClearView, ACTION_SEARCH_KEYWORDS_LIST, ACTION_SEARCH_KEYWORDS_VIEW }
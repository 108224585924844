import { toast } from "react-toastify";
import Api from '../../../../axios'
import { callLoader } from '../../../../common/actions/app'

let ACTION_MANAGER_LIST = {
    type: 'MANAGER_LIST',
    payload: {
        rows: [],
        total: 0,
        pagination: [],
    },
};

let ACTION_MANAGER_VIEW = {
    type: 'MANAGER_VIEW',
    payload: {
        fullname: '',
        email: '',
    },
};

let callManagerListGet = (filter = {}, pg = 1) => (dispatch) => {
    dispatch(callLoader(true))
    Api.get('/admin/register/manager?pg=' + pg).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        ACTION_MANAGER_LIST.payload.rows = data.rows
        ACTION_MANAGER_LIST.payload.total = data.total
        ACTION_MANAGER_LIST.payload.pagination = data.pagination
        dispatch(ACTION_MANAGER_LIST)
    })
}

let callManagerViewGet = (id) => (dispatch) => {
    dispatch(callLoader(true))
    Api.get('/admin/register/manager/' + id).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        ACTION_MANAGER_VIEW.payload.fullname = data.fullname
        ACTION_MANAGER_VIEW.payload.email = data.email
        dispatch(ACTION_MANAGER_VIEW)
    })
}

let callManagerPost = (data, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.post('/admin/register/manager', data).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

let callManagerPut = (id, data, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.put('/admin/register/manager/' + id, data).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

let callManagerClearView = () => (dispatch) => {
    ACTION_MANAGER_VIEW.payload.fullname = ''
    ACTION_MANAGER_VIEW.payload.email = ''
    dispatch(ACTION_MANAGER_VIEW)
}

let callManagerDelete = (id, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.delete('/admin/register/manager/' + id).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

export { callManagerListGet, callManagerViewGet, callManagerPost, callManagerPut, callManagerDelete, callManagerClearView, ACTION_MANAGER_LIST, ACTION_MANAGER_VIEW }
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, useParams, useSearchParams } from "react-router-dom"
import { bindActionCreators } from "redux"
import { callSearchKeywordsViewGet, callSearchKeywordsPost, callSearchKeywordsPut, callSearchKeywordsClearView } from "../../actions/searchKeywords"
import Modal from "../../../../../common/containers/Modal"
import { toast } from "react-toastify"

let SearchGroupEdit = ({ methods: { callSearchKeywordsPost, callSearchKeywordsViewGet, callSearchKeywordsPut, callSearchKeywordsClearView }, view }) => {
    let [searchParams] = useSearchParams();
    const params = useParams()
    const [id] = useState(!params.id ? '' : params.id)
    const [groupId] = useState(searchParams.get('groupId'))
    const [keywords, setKeywords] = useState('')
    const [additionals,setAdditionals] = useState('')
    const [unwanted,setUnwanted] = useState('')
    const [title, setTitle] = useState('')

    useEffect(() => {
        if(!groupId){
            toast.error('Grupo não selecionado!');
            window.navigate(`/search/keywords?v=${(new Date()).getMilliseconds()}`)
            return
        }

        if (!id) {
            setTitle('Nova Palavras-chave')
        } else {
            callSearchKeywordsViewGet(groupId, id)
            setTitle('Editar Palavras-chave')
        }
    }, [id, groupId, callSearchKeywordsViewGet, setTitle])

    useEffect(() => {
        setKeywords(view.keywords)
        setAdditionals(view.additionals)
        setUnwanted(view.unwanted)
    }, [setKeywords, setAdditionals, setUnwanted, view])

    const finishedSubmit = () => {
        setKeywords('')
        setAdditionals('')
        setUnwanted('')
        callSearchKeywordsClearView()
        window.navigate(`/search/keywords?groupId=${groupId}&v=${(new Date()).getMilliseconds()}`)
    }

    const submit = (e) => {
        e.preventDefault()

        let data = {
            keywords: encodeURI(keywords),
            additionals: encodeURI(additionals),
            unwanted: encodeURI(unwanted)
        }

        if (!id) {
            callSearchKeywordsPost(groupId, data, () => finishedSubmit())
        } else {
            callSearchKeywordsPut(groupId, id, data, () => finishedSubmit())
        }
    }

    return (
        <Modal title={title} pWidth={400}>
            <form onSubmit={submit}>
                <div className="form-row">
                    <div className="col-md-12">
                        <div className="form-row">
                            <div className="col-md-12 form-group">
                                <label className="required">Palavras-chave:</label>
                                <input type="text" className="form-control" value={keywords} onChange={e => setKeywords(e.target.value)} />
                            </div>
                            <div className="col-md-12 form-group">
                                <label className="required">Palavras complementares:</label>
                                <input type="text" className="form-control" value={additionals} onChange={e => setAdditionals(e.target.value)} />
                            </div>
                            <div className="col-md-12 form-group">
                                <label className="required">Palavras indesejadas:</label>
                                <input type="text" className="form-control" value={unwanted} onChange={e => setUnwanted(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix text-left mt-3">
                    <button className="btn btn-primary" type="submit"><i className={`mr-1 fas ${id ? 'fa-save' : 'fa-plus'} fa-white`}></i>{id ? 'Salvar' : 'Adicionar'}</button>
                    <Link to={`/search/keywords?groupId=${groupId}`} className="btn btn-secondary ml-3"><i className="fas fa-arrow-left mr-1"></i>Voltar</Link>
                </div>
            </form>
        </Modal>
    )
}

const mapStateToProps = ({ find: { searchKeywords: { view } } }) => ({
    view: view,
});

const mapDispatchToProps = (dispatch) => ({
    methods: bindActionCreators(
        {
            callSearchKeywordsViewGet,
            callSearchKeywordsPost,
            callSearchKeywordsClearView,
            callSearchKeywordsPut
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchGroupEdit)
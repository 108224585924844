import { ACTION_SEARCH_ALL_LIST } from "../actions/searchAll";
import { ACTION_SEARCH_GROUP_LIST, ACTION_SEARCH_GROUP_VIEW } from "../actions/searchGroups";
import { ACTION_SEARCH_KEYWORDS_LIST, ACTION_SEARCH_KEYWORDS_VIEW } from "../actions/searchKeywords";
import { ACTION_SEARCH_RESULT_LIST, ACTION_SEARCH_WARNING_LIST } from "../actions/searchResults";

let initialReducer = {
    searchGroups: {
        list: {
            rows: [],
            total: 0,
            pagination: [],
        },
        view: {
            name: '',
        },
    },
    searchKeywords: {
        list: {
            rows: [],
            total: 0,
            pagination: [],
        },
        view: {
            keywords: '',
            additionals: '',
            unwanted: ''
        },
    },
    searchResults: {
        list: {
            rows: [],
            total: 0,
            pagination: [],
        },
    },
    searchWarnings: {
        list: {
            rows: [],
            total: 0,
            pagination: [],
        },
    },
    searchAll: {
        list: {
            rows: [],
            total: 0,
            pagination: [],
        },
    },
}

let FindReducer = (state = initialReducer, action) => {
    switch (action.type) {
        case ACTION_SEARCH_GROUP_LIST.type:
            return {
                ...state,
                searchGroups: {
                    ...state.searchGroups,
                    list: {
                        rows: action.payload.rows,
                        total: action.payload.total,
                        pagination: action.payload.pagination
                    },
                }
            };
        case ACTION_SEARCH_GROUP_VIEW.type:
            return {
                ...state,
                searchGroups: {
                    ...state.searchGroups,
                    view: {
                        name: action.payload.name
                    },
                },
            };
        case ACTION_SEARCH_KEYWORDS_LIST.type:
            return {
                ...state,
                searchKeywords: {
                    ...state.searchKeywords,
                    list: {
                        rows: action.payload.rows,
                        total: action.payload.total,
                        pagination: action.payload.pagination
                    },
                }
            };
        case ACTION_SEARCH_KEYWORDS_VIEW.type:
            return {
                ...state,
                searchKeywords: {
                    ...state.searchKeywords,
                    view: {
                        keywords: action.payload.keywords,
                        additionals: action.payload.additionals,
                        unwanted: action.payload.unwanted
                    },
                },
            };
        case ACTION_SEARCH_RESULT_LIST.type:
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    list: {
                        rows: action.payload.rows,
                        total: action.payload.total,
                        pagination: action.payload.pagination
                    },
                }
            };
        case ACTION_SEARCH_WARNING_LIST.type:
            return {
                ...state,
                searchWarnings: {
                    ...state.searchWarnings,
                    list: {
                        rows: action.payload.rows,
                        total: action.payload.total,
                        pagination: action.payload.pagination
                    },
                }
            };
        case ACTION_SEARCH_ALL_LIST.type:
            return {
                ...state,
                searchAll: {
                    ...state.searchAll,
                    list: {
                        rows: action.payload.rows,
                        total: action.payload.total,
                        pagination: action.payload.pagination
                    },
                }
            };
        default:
            return state;
    }
}

export default FindReducer
import { toast } from "react-toastify";
import Api from '../../../../axios'
import { callLoader } from '../../../../common/actions/app'

let ACTION_USER_LIST = {
    type: 'USER_LIST',
    payload: {
        rows: [],
        total: 0,
        pagination: [],
    },
};

let ACTION_USER_VIEW = {
    type: 'USER_VIEW',
    payload: {
        fullname: '',
        document: '',
        email: '',
        companyIds: []
    },
};

let ACTION_USER_SELECT = {
    type: 'USER_SELECT',
    payload: [],
};

let callUserListGet = (filter = {}, pg = 1) => (dispatch) => {
    dispatch(callLoader(true))
    Api.get('/admin/register/user?pg=' + pg).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        ACTION_USER_LIST.payload.rows = data.rows
        ACTION_USER_LIST.payload.total = data.total
        ACTION_USER_LIST.payload.pagination = data.pagination
        dispatch(ACTION_USER_LIST)
    })
}

let callUserViewGet = (id) => (dispatch) => {
    dispatch(callLoader(true))
    Api.get('/admin/register/user/' + id).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        ACTION_USER_VIEW.payload.fullname = data.fullname
        ACTION_USER_VIEW.payload.document = data.document
        ACTION_USER_VIEW.payload.email = data.email
        ACTION_USER_VIEW.payload.companyIds = data.companyIds
        dispatch(ACTION_USER_VIEW)
    })
}

let callUserPost = (data, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.post('/admin/register/user', data).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

let callUserPut = (id, data, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.put('/admin/register/user/' + id, data).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

let callUserClearView = () => (dispatch) => {
    ACTION_USER_VIEW.payload.fullname = ''
    ACTION_USER_VIEW.payload.document = ''
    ACTION_USER_VIEW.payload.email = ''
    ACTION_USER_VIEW.payload.companyIds = []
    dispatch(ACTION_USER_VIEW)
}

let callUserDelete = (id, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.delete('/admin/register/user/' + id).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

let callUserSelectGet = (filter = {}) => (dispatch) => {
    dispatch(callLoader(true))
    Api.get('/admin/register/user/select').then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        ACTION_USER_SELECT.payload = data
        dispatch(ACTION_USER_SELECT)
    })
}

export { callUserListGet, callUserViewGet, callUserPost, callUserPut, callUserDelete, callUserClearView, callUserSelectGet, ACTION_USER_LIST, ACTION_USER_VIEW, ACTION_USER_SELECT }
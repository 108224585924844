import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { bindActionCreators } from "redux"
import { callUserViewGet, callUserPost, callUserPut } from "../../actions/users"
import InputMask from "../../../../../common/containers/InputMask"
import { callCompanySelectGet } from "../../actions/companies";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

let UserEdit = ({ setPageAttr, methods: { callUserPost, callUserViewGet, callUserPut, callCompanySelectGet }, view, selectCompanies }) => {
    const params = useParams()
    const [id] = useState(!params.id ? '' : params.id)
    const [fullname, setFullname] = useState('')
    const [document, setDocument] = useState('')
    const [email, setEmail] = useState('')
    const [companies, setCompanies] = useState([])
    const [pass, setPass] = useState('')
    const [passConfirm, setPassConfirm] = useState('')

    useEffect(() => {
        callCompanySelectGet()
        let tabs
        if (!id) {
            tabs = [
                {
                    active: false,
                    to: '/admin/register/user',
                    label: 'Informações'
                },
                {
                    active: true,
                    to: '/admin/register/user/add',
                    label: 'Adicionar'
                }
            ]
        } else {
            callUserViewGet(id)
            tabs = [
                {
                    active: false,
                    to: '/admin/register/user',
                    label: 'Informações'
                },
                {
                    active: true,
                    to: '/admin/register/user/edit/' + id,
                    label: 'Editar'
                }
            ]
        }

        setPageAttr({
            title: 'Usuário - ' + (!id ? 'Novo' : 'Editar'),
            caption: 'Preencha os campos para inserir as informações',
            btns: [],
            tabs: tabs
        })
    }, [setPageAttr, id, callUserViewGet, callCompanySelectGet])

    useEffect(() => {
        setFullname(view.fullname)
        setDocument(view.document)
        setEmail(view.email)
        setCompanies(view.companyIds)
    }, [view])

    const finishedSubmit = () => {
        setFullname('')
        setDocument('')
        setEmail('')
        setCompanies([])
        setPass('')
        setPassConfirm('')
        window.navigate('/admin/register/user')
    }

    const submit = (e) => {
        e.preventDefault()

        let i, companyIds = [];
        for (i = 0; i < companies.length; i++) {
            companyIds.push(companies[i].id)
        }

        let data = {
            fullname,
            document,
            email,
            companyIds,
            pass,
            passConfirm
        }

        if (!id) {
            callUserPost(data, () => finishedSubmit())
        } else {
            callUserPut(id, data, () => finishedSubmit())
        }
    }

    return (
        <form onSubmit={submit}>
            <div className="form-row">
                <div className="col-md-12">
                    <div className="form-row">
                        <div className="col-md-6 form-group">
                            <label className="required">Nome Completo:</label>
                            <input type="text" className="form-control" value={fullname} onChange={e => setFullname(e.target.value)} />
                        </div>
                        <div className="col-md-6 form-group">
                            <label className="required">CPF:</label>
                            <InputMask mask="cpf" value={document} onChange={v => setDocument(v)} />
                        </div>
                        <div className="col-md-6 form-group">
                            <label className="required">E-mail:</label>
                            <input type="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)} />
                        </div>
                        <div className="col-md-6 form-group">
                            <label className="required">Empresas</label>
                            <Typeahead
                                id="companies"
                                labelKey="description"
                                multiple
                                onChange={(e) => setCompanies(e)}
                                options={selectCompanies}
                                placeholder="Selecione as empresas vinculadas..."
                                selected={companies}
                            />
                        </div>
                        <div className="col-md-6 form-group">
                            <label className="required">Senha:</label>
                            <input type="password" className="form-control" value={pass} onChange={e => setPass(e.target.value)} />
                        </div>
                        <div className="col-md-6 form-group">
                            <label className="required">Confirmar Senha:</label>
                            <input type="password" className="form-control" value={passConfirm} onChange={e => setPassConfirm(e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="clearfix text-left mt-3">
                <button className="btn btn-primary" type="submit"><i className="mr-1 fas fa-save fa-white"></i>Salvar</button>
                <Link to={'/admin/register/user'} className="btn btn-secondary ml-3"><i className="fas fa-arrow-left mr-1"></i>Voltar</Link>
            </div>
        </form>
    )
}

const mapStateToProps = ({ register: { users: { view }, companies } }) => ({
    view: view,
    selectCompanies: companies.select
});

const mapDispatchToProps = (dispatch) => ({
    methods: bindActionCreators(
        {
            callUserViewGet,
            callUserPost,
            callUserPut,
            callCompanySelectGet
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit)
import { connect } from 'react-redux'
import { Route, Routes } from 'react-router'
import RequesterList from './RequesterList'
import RequesterEdit from './RequesterEdit'
import { useState } from 'react'
import Sidebar from '../../../../../common/containers/Sidebar'
import Card from '../../../../../common/containers/Card'

let RequesterPage = () => {
    const [pageAttr, setPageAttr] = useState({})

    return (
        <Sidebar pWidth='70%'>
            <Card {...pageAttr} closeTo='/admin'>
                <Routes>
                    <Route path='/edit/:id' element={<RequesterEdit setPageAttr={setPageAttr} />} />
                    <Route path='/add' element={<RequesterEdit setPageAttr={setPageAttr} />} />
                    <Route index element={<RequesterList setPageAttr={setPageAttr} />} />
                </Routes>
            </Card>
        </Sidebar>
    )
}

export default connect()(RequesterPage)
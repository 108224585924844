import { connect } from 'react-redux'
import Sidebar from '../../../../../common/containers/Sidebar'
import { Link, Route, Routes, useSearchParams } from 'react-router-dom';
import { callSearchGroupListGet, callSearchGroupDelete, callSearchGroupClearView } from '../../actions/searchGroups';
import { callSearchKeywordsListGet, callSearchKeywordsClearView, callSearchKeywordsDelete } from '../../actions/searchKeywords';
import { bindActionCreators } from 'redux';
import Swal from 'sweetalert2';
import { useEffect, useState } from 'react';
import TableView from '../../../../../common/containers/TableView';
import SearchGroupEdit from './SearchGroupEdit';
import SearchKeywordsEdit from './SearchKeywordsEdit';

let SearchGroupPage = ({ listGroup, listKeywords, methods: { callSearchGroupListGet, callSearchGroupDelete, callSearchGroupClearView, callSearchKeywordsListGet, callSearchKeywordsClearView, callSearchKeywordsDelete } }) => {
    let [searchParams] = useSearchParams();
    const [groupId, setGroupId] = useState(null)

    const removeGroup = (id) => {
        Swal.fire({
            title: 'Tem certeza que deseja deletar? Essa ação não poderá ser desfeita!',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
        }).then((result) => {
            if (result.isConfirmed) {
                callSearchGroupDelete(id, () => {
                    setGroupId(null)
                    let pg = searchParams.get('pgGroup')
                    pg = pg ? pg : 1
                    callSearchGroupListGet({}, pg)
                })
            }
        })
    }

    const removeKeyword = (groupId, id) => {
        Swal.fire({
            title: 'Tem certeza que deseja deletar? Essa ação não poderá ser desfeita!',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Sim',
            denyButtonText: `Não`,
        }).then((result) => {
            if (result.isConfirmed) {
                callSearchKeywordsDelete(groupId, id, () => {
                    let pg = searchParams.get('pgKeywords')
                    pg = pg ? pg : 1
                    callSearchKeywordsListGet(groupId, {}, pg)
                })
            }
        })
    }

    const headersGroup = [
        {
            type: 'info',
            name: 'name',
            align: 'left',
            label: 'Nome'
        },
        {
            type: 'custom',
            align: 'right',
            label: 'Opções',
            custom: (a) => {
                return (
                    <>
                        <Link to={'/search/keywords/edit-group/' + a.id} className='btn btn-sm btn-primary'><i className='fas fa-edit'></i></Link>
                        <span className='btn btn-sm btn-danger ml-2' onClick={() => removeGroup(a.id)}><i className='fas fa-trash'></i></span>
                        <Link to={'/search/keywords?groupId=' + a.id} className='btn btn-sm btn-warning ml-2'><i className='fas fa-arrow-right'></i></Link>
                    </>
                )
            }
        }
    ]

    const headersKeywords = [
        {
            type: 'info',
            name: 'keywords',
            align: 'left',
            label: 'Palavras-chave'
        },
        {
            type: 'info',
            name: 'additionals',
            align: 'left',
            label: 'Complementares'
        },
        {
            type: 'info',
            name: 'unwanted',
            align: 'left',
            label: 'Indesejadas'
        },
        {
            type: 'custom',
            align: 'right',
            label: 'Opções',
            custom: (a) => {
                return (
                    <>
                        <Link to={`/search/keywords/edit/${a.id}?groupId=${groupId}`} className='btn btn-sm btn-primary'><i className='fas fa-edit'></i></Link>
                        <span className='btn btn-sm btn-danger ml-2' onClick={() => removeKeyword(groupId, a.id)}><i className='fas fa-trash'></i></span>
                    </>
                )
            }
        }
    ]

    useEffect(() => {
        setGroupId(null)
        callSearchGroupClearView()
        callSearchKeywordsClearView()
        let pgGroup = searchParams.get('pgGroup')
        let pgKeywords = searchParams.get('pgKeywords')
        let groupId = searchParams.get('groupId')
        pgGroup = pgGroup ? pgGroup : 1
        pgKeywords = pgKeywords ? pgKeywords : 1
        if (groupId) {
            setGroupId(groupId)
            callSearchKeywordsListGet(groupId, {}, pgKeywords)
        }
        callSearchGroupListGet({}, pgGroup)
    }, [callSearchGroupListGet, callSearchGroupClearView, callSearchKeywordsListGet, callSearchKeywordsClearView, setGroupId, searchParams])

    return (
        <>
            <Routes>
                <Route path='edit/:id' element={<SearchKeywordsEdit />} />
                <Route path='add' element={<SearchKeywordsEdit />} />
                <Route path='edit-group/:id' element={<SearchGroupEdit />} />
                <Route path='add-group' element={<SearchGroupEdit />} />
            </Routes>
            <Sidebar pWidth='90%'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12 mt-3 mb-3'>
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <h2 className="card-title">Palavras-Chaves</h2>
                                            <p className="card-text">Crie suas palavras chaves para filtrar o conteúdo das licitações.</p>
                                        </div>
                                        <div>
                                            <Link className='btn btn-secondary' to={`/?v=${(new Date()).getMilliseconds()}`}><i className='fas fa-times'></i></Link>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <ul className="nav nav-tabs mt-4">
                                                <li className="nav-item">
                                                    <Link className='nav-link active' to='/search/keywords'>Grupos</Link>
                                                </li>
                                            </ul>
                                            <div className="tabcontent-border">
                                                <div className='text-right mb-3'>
                                                    <Link to='/search/keywords/add-group' className='btn btn-primary btn-sm'><i className='fas fa-plus mr-1'></i>Adicionar</Link>
                                                </div>
                                                <TableView headers={headersGroup} rows={listGroup.rows} total={listGroup.total} pagination={listGroup.pagination} paginationTo={'/search/keywords'} />
                                            </div>
                                        </div>
                                        <div className='col-md-8'>
                                            <ul className="nav nav-tabs mt-4">
                                                <li className="nav-item">
                                                    <Link className='nav-link active' to='/search/keywords'>Palavras-Chaves</Link>
                                                </li>
                                            </ul>
                                            <div className="tabcontent-border">
                                                {groupId ? (
                                                    <>
                                                        <div className='text-right mb-3'>
                                                            <Link to={`/search/keywords/add?groupId=${groupId}`} className='btn btn-primary btn-sm'><i className='fas fa-plus mr-1'></i>Adicionar</Link>
                                                        </div>
                                                        <TableView headers={headersKeywords} rows={listKeywords.rows} total={listKeywords.total} pagination={listKeywords.pagination} paginationTo={'/search/keywords'} />
                                                    </>
                                                ) : (
                                                    <>
                                                        Selecione um grupo para adicionar as palavras chaves
                                                    </>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Sidebar>
        </>
    )
}

const mapStateToProps = ({ find: { searchGroups, searchKeywords } }) => ({
    listGroup: searchGroups.list,
    listKeywords: searchKeywords.list
});

const mapDispatchToProps = (dispatch) => ({
    methods: bindActionCreators(
        {
            callSearchGroupListGet,
            callSearchGroupDelete,
            callSearchGroupClearView,
            callSearchKeywordsListGet,
            callSearchKeywordsClearView,
            callSearchKeywordsDelete
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchGroupPage)
import { connect } from 'react-redux'
import { Route, Routes } from 'react-router'
import PortalList from './PortalList'
import { useState } from 'react'
import Sidebar from '../../../../../common/containers/Sidebar'
import Card from '../../../../../common/containers/Card'

let PortalPage = () => {
    const [pageAttr, setPageAttr] = useState({})

    return (
        <Sidebar pWidth='70%'>
            <Card {...pageAttr} closeTo='/admin'>
                <Routes>
                    <Route index element={<PortalList setPageAttr={setPageAttr} />} />
                </Routes>
            </Card>
        </Sidebar>
    )
}

export default connect()(PortalPage)
import { ACTION_PORTAL_LIST } from "../actions/portals";
import { ACTION_REQUESTER_LIST, ACTION_REQUESTER_VIEW } from "../actions/requesters";

let initialReducer = {
    portals: {
        list: {
            rows: [],
            total: 0,
            pagination: [],
        },
    },
    requesters: {
        list: {
            rows: [],
            total: 0,
            pagination: [],
        },
        view: {
            code: '',
            name: '',
        },
    },
}

let IntegrationReducer = (state = initialReducer, action) => {
    switch (action.type) {
        case ACTION_PORTAL_LIST.type:
            return {
                ...state,
                portals: {
                    ...state.portals,
                    list: {
                        rows: action.payload.rows,
                        total: action.payload.total,
                        pagination: action.payload.pagination
                    },
                }
            };
        case ACTION_REQUESTER_LIST.type:
            return {
                ...state,
                requesters: {
                    ...state.requesters,
                    list: {
                        rows: action.payload.rows,
                        total: action.payload.total,
                        pagination: action.payload.pagination
                    },
                }
            };
        case ACTION_REQUESTER_VIEW.type:
            return {
                ...state,
                requesters: {
                    ...state.requesters,
                    view: {
                        code: action.payload.code,
                        name: action.payload.name
                    },
                },
            };
        default:
            return state;
    }
}

export default IntegrationReducer
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { bindActionCreators } from "redux"
import { callCompanyViewGet, callCompanyPost, callCompanyPut } from "../../actions/companies"
import InputMask from "../../../../../common/containers/InputMask"

let CompanyEdit = ({ setPageAttr, methods: { callCompanyPost, callCompanyViewGet, callCompanyPut }, view }) => {
    const params = useParams()
    const [id] = useState(!params.id ? '' : params.id)
    const [fullname, setFullname] = useState('')
    const [document, setDocument] = useState('')
    const [email, setEmail] = useState('')

    useEffect(() => {
        let tabs
        if (!id) {
            tabs = [
                {
                    active: false,
                    to: '/admin/register/company',
                    label: 'Informações'
                },
                {
                    active: true,
                    to: '/admin/register/company/add',
                    label: 'Adicionar'
                }
            ]
        } else {
            callCompanyViewGet(id)
            tabs = [
                {
                    active: false,
                    to: '/admin/register/company',
                    label: 'Informações'
                },
                {
                    active: true,
                    to: '/admin/register/company/edit/' + id,
                    label: 'Editar'
                }
            ]
        }

        setPageAttr({
            title: 'Empresa - ' + (!id ? 'Novo' : 'Editar'),
            caption: 'Preencha os campos para inserir as informações',
            btns: [],
            tabs: tabs
        })
    }, [setPageAttr, id, callCompanyViewGet])

    useEffect(() => {
        setFullname(view.fullname)
        setDocument(view.document)
        setEmail(view.email)
    }, [view])

    const finishedSubmit = () => {
        setFullname('')
        setDocument('')
        setEmail('')
        window.navigate('/admin/register/company')
    }

    const submit = (e) => {
        e.preventDefault()

        let data = {
            fullname,
            document,
            email,
        }

        if (!id) {
            callCompanyPost(data, () => finishedSubmit())
        } else {
            callCompanyPut(id, data, () => finishedSubmit())
        }
    }

    return (
        <form onSubmit={submit}>
            <div className="form-row">
                <div className="col-md-12">
                    <div className="form-row">
                        <div className="col-md-6 form-group">
                            <label className="required">Razão Social:</label>
                            <input type="text" className="form-control" value={fullname} onChange={e => setFullname(e.target.value)} />
                        </div>
                        <div className="col-md-6 form-group">
                            <label className="required">CNPJ:</label>
                            <InputMask mask="cnpj" value={document} onChange={v => setDocument(v)} />
                        </div>
                        <div className="col-md-6 form-group">
                            <label className="required">E-mail:</label>
                            <input type="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="clearfix text-left mt-3">
                <button className="btn btn-primary" type="submit"><i className="mr-1 fas fa-save fa-white"></i>Salvar</button>
                <Link to={'/admin/register/company'} className="btn btn-secondary ml-3"><i className="fas fa-arrow-left mr-1"></i>Voltar</Link>
            </div>
        </form>
    )
}

const mapStateToProps = ({ register: { companies: { view } } }) => ({
    view: view,
});

const mapDispatchToProps = (dispatch) => ({
    methods: bindActionCreators(
        {
            callCompanyViewGet,
            callCompanyPost,
            callCompanyPut
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEdit)
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { bindActionCreators } from "redux"
import { callSearchGroupViewGet, callSearchGroupPost, callSearchGroupPut } from "../../actions/searchGroups"
import Modal from "../../../../../common/containers/Modal"

let SearchGroupEdit = ({ methods: { callSearchGroupPost, callSearchGroupViewGet, callSearchGroupPut }, view }) => {
    const params = useParams()
    const [id] = useState(!params.id ? '' : params.id)
    const [name, setName] = useState('')
    const [title, setTitle] = useState('')

    useEffect(() => {
        if (!id) {
            setTitle('Novo Grupo')
        } else {
            callSearchGroupViewGet(id)
            setTitle('Editar Grupo')
        }
    }, [id, callSearchGroupViewGet, setTitle])

    useEffect(() => {
        setName(view.name)
    }, [view])

    const finishedSubmit = () => {
        setName('')
        window.navigate(`/search/keywords?v=${(new Date()).getMilliseconds()}`)
    }

    const submit = (e) => {
        e.preventDefault()

        let data = {
            name: encodeURI(name),
        }

        if (!id) {
            callSearchGroupPost(data, () => finishedSubmit())
        } else {
            callSearchGroupPut(id, data, () => finishedSubmit())
        }
    }

    return (
        <Modal title={title} pWidth={400}>
            <form onSubmit={submit}>
                <div className="form-row">
                    <div className="col-md-12">
                        <div className="form-row">
                            <div className="col-md-12 form-group">
                                <label className="required">Nome:</label>
                                <input type="text" className="form-control" value={name} onChange={e => setName(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix text-left mt-3">
                    <button className="btn btn-primary" type="submit"><i className={`mr-1 fas ${id ? 'fa-save' : 'fa-plus'} fa-white`}></i>{id ? 'Salvar' : 'Adicionar'}</button>
                    <Link to={'/search/keywords'} className="btn btn-secondary ml-3"><i className="fas fa-arrow-left mr-1"></i>Voltar</Link>
                </div>
            </form>
        </Modal>
    )
}

const mapStateToProps = ({ find: { searchGroups: { view } } }) => ({
    view: view,
});

const mapDispatchToProps = (dispatch) => ({
    methods: bindActionCreators(
        {
            callSearchGroupViewGet,
            callSearchGroupPost,
            callSearchGroupPut
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchGroupEdit)
import Api from '../../../../axios'
import { callLoader } from '../../../../common/actions/app'

let ACTION_PORTAL_LIST = {
    type: 'PORTAL_LIST',
    payload: {
        rows: [],
        total: 0,
        pagination: [],
    },
};

let callPortalListGet = (filter = {}, pg = 1) => (dispatch) => {
    dispatch(callLoader(true))
    Api.get('/admin/integration/portal?pg=' + pg).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        ACTION_PORTAL_LIST.payload.rows = data.rows
        ACTION_PORTAL_LIST.payload.total = data.total
        ACTION_PORTAL_LIST.payload.pagination = data.pagination
        dispatch(ACTION_PORTAL_LIST)
    })
}

export { callPortalListGet,  ACTION_PORTAL_LIST }
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { bindActionCreators } from "redux"
import { callRequesterViewGet, callRequesterPost, callRequesterPut } from "../../actions/requesters"

let RequesterEdit = ({ setPageAttr, methods: { callRequesterPost, callRequesterViewGet, callRequesterPut }, view }) => {
    const params = useParams()
    const [id] = useState(!params.id ? '' : params.id)
    const [code, setCode] = useState('')
    const [name, setName] = useState('')

    useEffect(() => {
        let tabs
        if (!id) {
            tabs = [
                {
                    active: false,
                    to: '/admin/integration/requester',
                    label: 'Informações'
                },
                {
                    active: true,
                    to: '/admin/integration/requester/add',
                    label: 'Adicionar'
                }
            ]
        } else {
            callRequesterViewGet(id)
            tabs = [
                {
                    active: false,
                    to: '/admin/integration/requester',
                    label: 'Informações'
                },
                {
                    active: true,
                    to: '/admin/integration/requester/edit/' + id,
                    label: 'Editar'
                }
            ]
        }

        setPageAttr({
            title: 'Solicitante - ' + (!id ? 'Novo' : 'Editar'),
            caption: 'Preencha os campos para inserir as informações',
            btns: [],
            tabs: tabs
        })
    }, [setPageAttr, id, callRequesterViewGet])

    useEffect(() => {
        setCode(view.code)
        setName(view.name)
    }, [view])

    const finishedSubmit = () => {
        setCode('')
        setName('')
        window.navigate('/admin/integration/requester')
    }

    const submit = (e) => {
        e.preventDefault()

        let data = {
            code,
            name,
        }

        if (!id) {
            callRequesterPost(data, () => finishedSubmit())
        } else {
            callRequesterPut(id, data, () => finishedSubmit())
        }
    }

    return (
        <form onSubmit={submit}>
            <div className="form-row">
                <div className="col-md-12">
                    <div className="form-row">
                        <div className="col-md-4 form-group">
                            <label className="required">Código:</label>
                            <input type="number" className="form-control" value={code} onChange={e => setCode(e.target.value)} />
                        </div>
                        <div className="col-md-8 form-group">
                            <label className="required">Nome:</label>
                            <input type="text" className="form-control" value={name} onChange={e => setName(e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="clearfix text-left mt-3">
                <button className="btn btn-primary" type="submit"><i className="mr-1 fas fa-save fa-white"></i>Salvar</button>
                <Link to={'/admin/integration/requester'} className="btn btn-secondary ml-3"><i className="fas fa-arrow-left mr-1"></i>Voltar</Link>
            </div>
        </form>
    )
}

const mapStateToProps = ({ integration: { requesters: { view } } }) => ({
    view: view,
});

const mapDispatchToProps = (dispatch) => ({
    methods: bindActionCreators(
        {
            callRequesterViewGet,
            callRequesterPost,
            callRequesterPut
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(RequesterEdit)
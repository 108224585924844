import { connect } from 'react-redux'
import { Route, Routes } from 'react-router'
import CompanyList from './CompanyList'
import CompanyEdit from './CompanyEdit'
import { useState } from 'react'
import Sidebar from '../../../../../common/containers/Sidebar'
import Card from '../../../../../common/containers/Card'

let CompanyPage = () => {
    const [pageAttr, setPageAttr] = useState({})

    return (
        <Sidebar pWidth='80%'>
            <Card {...pageAttr} closeTo='/admin'>
                <Routes>
                    <Route path='/edit/:id' element={<CompanyEdit setPageAttr={setPageAttr} />} />
                    <Route path='/add' element={<CompanyEdit setPageAttr={setPageAttr} />} />
                    <Route index element={<CompanyList setPageAttr={setPageAttr} />} />
                </Routes>
            </Card>
        </Sidebar>
    )
}

export default connect()(CompanyPage)
import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'
import AdminReducer from './common/reducers';
import AppReducer from '../common/reducers';
import RegisterReducers from './modules/register/reducers';
import IntegrationReducer from './modules/integration/reducers';

let reducers = combineReducers({
    admin: AdminReducer,
    app: AppReducer,
    register: RegisterReducers,
    integration: IntegrationReducer,
});
let store = createStore(reducers, applyMiddleware(thunk))
export default store
import { toast } from "react-toastify";
import Api from '../../../../axios'
import { callLoader } from '../../../../common/actions/app'

let ACTION_SEARCH_GROUP_LIST = {
    type: 'SEARCH_GROUP_LIST',
    payload: {
        rows: [],
        total: 0,
        pagination: [],
    },
};

let ACTION_SEARCH_GROUP_VIEW = {
    type: 'SEARCH_GROUP_VIEW',
    payload: {
        name: '',
    },
};

let callSearchGroupListGet = (filter = {}, pg = 1) => (dispatch) => {
    dispatch(callLoader(true))
    Api.get('/user/search/group?pg=' + pg).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        ACTION_SEARCH_GROUP_LIST.payload.rows = data.rows
        ACTION_SEARCH_GROUP_LIST.payload.total = data.total
        ACTION_SEARCH_GROUP_LIST.payload.pagination = data.pagination
        dispatch(ACTION_SEARCH_GROUP_LIST)
    })
}

let callSearchGroupViewGet = (id) => (dispatch) => {
    dispatch(callLoader(true))
    Api.get('/user/search/group/' + id).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        ACTION_SEARCH_GROUP_VIEW.payload.name = data.name
        dispatch(ACTION_SEARCH_GROUP_VIEW)
    })
}

let callSearchGroupPost = (data, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.post('/user/search/group', data).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

let callSearchGroupPut = (id, data, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.put('/user/search/group/' + id, data).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

let callSearchGroupClearView = () => (dispatch) => {
    ACTION_SEARCH_GROUP_VIEW.payload.name = ''
    dispatch(ACTION_SEARCH_GROUP_VIEW)
}

let callSearchGroupDelete = (id, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.delete('/user/search/group/' + id).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

export { callSearchGroupListGet, callSearchGroupViewGet, callSearchGroupPost, callSearchGroupPut, callSearchGroupDelete, callSearchGroupClearView, ACTION_SEARCH_GROUP_LIST, ACTION_SEARCH_GROUP_VIEW }
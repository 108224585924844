import { ACTION_COMPANY_LIST, ACTION_COMPANY_SELECT, ACTION_COMPANY_VIEW } from "../actions/companies";
import { ACTION_MANAGER_LIST, ACTION_MANAGER_VIEW } from "../actions/managers";
import { ACTION_USER_LIST, ACTION_USER_SELECT, ACTION_USER_VIEW } from "../actions/users";

let initialReducer = {
    managers: {
        list: {
            rows: [],
            total: 0,
            pagination: [],
        },
        view: {
            fullname: '',
            email: '',
        },
    },
    companies: {
        list: {
            rows: [],
            total: 0,
            pagination: [],
        },
        view: {
            fullname: '',
            document: '',
            email: '',
        },
        select: [],
    },
    users: {
        list: {
            rows: [],
            total: 0,
            pagination: [],
        },
        view: {
            fullname: '',
            document: '',
            email: '',
            companyIds: []
        },
        select: [],
    },
}

let RegisterReducers = (state = initialReducer, action) => {
    switch (action.type) {
        case ACTION_MANAGER_LIST.type:
            return {
                ...state,
                managers: {
                    ...state.managers,
                    list: {
                        rows: action.payload.rows,
                        total: action.payload.total,
                        pagination: action.payload.pagination
                    },
                }
            };
        case ACTION_MANAGER_VIEW.type:
            return {
                ...state,
                managers: {
                    ...state.managers,
                    view: {
                        fullname: action.payload.fullname,
                        email: action.payload.email
                    },
                },
            };
        case ACTION_COMPANY_LIST.type:
            return {
                ...state,
                companies: {
                    ...state.companies,
                    list: {
                        rows: action.payload.rows,
                        total: action.payload.total,
                        pagination: action.payload.pagination
                    },
                }
            };
        case ACTION_COMPANY_VIEW.type:
            return {
                ...state,
                companies: {
                    ...state.companies,
                    view: {
                        fullname: action.payload.fullname,
                        document: action.payload.document,
                        email: action.payload.email
                    },
                },
            };
        case ACTION_COMPANY_SELECT.type:
            return {
                ...state,
                companies: {
                    ...state.companies,
                    select: action.payload
                },
            };
        case ACTION_USER_LIST.type:
            return {
                ...state,
                users: {
                    ...state.users,
                    list: {
                        rows: action.payload.rows,
                        total: action.payload.total,
                        pagination: action.payload.pagination
                    },
                }
            };
        case ACTION_USER_VIEW.type:
            return {
                ...state,
                users: {
                    ...state.users,
                    view: {
                        fullname: action.payload.fullname,
                        document: action.payload.document,
                        email: action.payload.email,
                        companyIds: action.payload.companyIds
                    },
                },
            };
        case ACTION_USER_SELECT.type:
            return {
                ...state,
                users: {
                    ...state.users,
                    select: action.payload
                },
            };
        default:
            return state;
    }
}

export default RegisterReducers
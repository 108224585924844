import { toast } from "react-toastify";
import Api from '../../../../axios'
import { callLoader } from '../../../../common/actions/app'

let ACTION_COMPANY_LIST = {
    type: 'COMPANY_LIST',
    payload: {
        rows: [],
        total: 0,
        pagination: [],
    },
};

let ACTION_COMPANY_VIEW = {
    type: 'COMPANY_VIEW',
    payload: {
        fullname: '',
        document: '',
        email: '',
    },
};

let ACTION_COMPANY_SELECT = {
    type: 'COMPANY_SELECT',
    payload: [],
};

let callCompanyListGet = (filter = {}, pg = 1) => (dispatch) => {
    dispatch(callLoader(true))
    Api.get('/admin/register/company?pg=' + pg).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        ACTION_COMPANY_LIST.payload.rows = data.rows
        ACTION_COMPANY_LIST.payload.total = data.total
        ACTION_COMPANY_LIST.payload.pagination = data.pagination
        dispatch(ACTION_COMPANY_LIST)
    })
}

let callCompanyViewGet = (id) => (dispatch) => {
    dispatch(callLoader(true))
    Api.get('/admin/register/company/' + id).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        ACTION_COMPANY_VIEW.payload.fullname = data.fullname
        ACTION_COMPANY_VIEW.payload.document = data.document
        ACTION_COMPANY_VIEW.payload.email = data.email
        dispatch(ACTION_COMPANY_VIEW)
    })
}

let callCompanyPost = (data, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.post('/admin/register/company', data).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

let callCompanyPut = (id, data, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.put('/admin/register/company/' + id, data).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

let callCompanyClearView = () => (dispatch) => {
    ACTION_COMPANY_VIEW.payload.fullname = ''
    ACTION_COMPANY_VIEW.payload.document = ''
    ACTION_COMPANY_VIEW.payload.email = ''
    dispatch(ACTION_COMPANY_VIEW)
}

let callCompanyDelete = (id, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.delete('/admin/register/company/' + id).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

let callCompanySelectGet = (filter = {}) => (dispatch) => {
    dispatch(callLoader(true))
    Api.get('/admin/register/company/select').then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        ACTION_COMPANY_SELECT.payload = data
        dispatch(ACTION_COMPANY_SELECT)
    })
}

export { callCompanyListGet, callCompanyViewGet, callCompanyPost, callCompanyPut, callCompanyDelete, callCompanyClearView, callCompanySelectGet, ACTION_COMPANY_LIST, ACTION_COMPANY_VIEW, ACTION_COMPANY_SELECT }
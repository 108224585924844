import { Link } from "react-router-dom"
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { callSearchAllGet } from "../../find/actions/searchAll";
import { useEffect, useState } from "react";
import './../styles/SearchAll.css'

let SearchAll = ({ myResults, methods: { callSearchAllGet } }) => {
    const [search, setSearch] = useState('')
    useEffect(() => {
        callSearchAllGet();
    }, [callSearchAllGet])

    const markKeywords = (keywords, content) => {
        if (!keywords) {
            return content;
        }
        return content.replace(keywords, `<span class="mark-keywords">${keywords}</span>`)
    }

    const markAdditionals = (additionals, content) => {
        if (!additionals) {
            return content;
        }
        return content.replace(additionals, `<span class="mark-additionals">${additionals}</span>`)
    }

    const submitSearch = (e) => {
        e.preventDefault()

        callSearchAllGet({}, 1, search);
    }

    return (
        <div className="container-fluid p-4">
            <div className="d-flex justify-content-between">
                <div>
                    <h2 className="card-title">Pesquisa Avulsa</h2>
                    <p className="card-text">Encontre as melhores oportunidades para o seu negócio! Receba diversas licitações alinhadas com o produto ou serviço da sua empresa.</p>
                </div>
            </div>
            <div className="card mt-3">
                <div className="card-body">
                    <form onSubmit={submitSearch}>
                        <div className="form-row">
                            <div className="col-md-8 form-group">
                                <label className="required">Palavras:</label>
                                <input type="text" className="form-control" value={search} onChange={e => setSearch(e.target.value)} />
                            </div>
                            <div className="col-md-4 form-group">
                                <label style={{visibility: 'hidden'}}></label><br/>
                                <button className="btn btn-primary mt-2" type="submit"><i className={`mr-1 fas fa-search fa-white`}></i>Pesquisar</button>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
            <div className="clearfix mt-3">
                {myResults.rows.length === 0 ? (
                    <div className="card">
                        <div className="card-body">Nenhum aviso encontrado!</div>
                    </div>
                ) : ''}
                {myResults.rows.map((a, ai) => (
                    <div className={`card results ${ai > 0 ? 'mt-3' : ''}`} key={ai}>
                        <div className='card-header'>
                            <div className="d-flex">
                                <div className="flex-fill"><input type="checkbox" className="mr-2" /> {a.requester}</div>
                                <div>
                                    <Link to={a.link} target="_blank" className="btn btn-outline-primary"><i className="fas fa-globe-americas"></i></Link>
                                    <button type="button" className="btn btn-outline-primary ml-2"><i className="fas fa-paperclip"></i></button>
                                    <button type="button" className="btn btn-outline-primary ml-2"><i className="fas fa-external-link-alt"></i></button>
                                    <button type="button" className="btn btn-outline-primary ml-2"><i className="fas fa-star"></i></button>
                                    <button type="button" className="btn btn-outline-primary ml-2"><i className="fas fa-university"></i></button>
                                    <button type="button" className="btn btn-outline-danger ml-2"><i className="fas fa-ban"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="box-bg">
                                        <div className="row">
                                            <div className="col-md-3 topic">
                                                <label className="v1"><i className="fas fa-university mr-2"></i><span>Portal de Captura</span></label>
                                                <span className="v2 bold">{a.portal}</span>
                                            </div>
                                            <div className="col-md-3 topic">
                                                <label className="v1"><i className="fas fa-hashtag mr-2"></i><span>Licitação</span></label>
                                                <span className="v2">{a.code}</span>
                                            </div>
                                            <div className="col-md-3 topic">
                                                <label className="v1"><i className="fas fa-folder mr-2"></i><span>UASG</span></label>
                                                <span className="v2">{a.requesterCode}</span>
                                            </div>
                                            <div className="col-md-3 topic">
                                                <label className="v1"><i className="fas fa-cubes mr-2"></i><span>Modalidade</span></label>
                                                <span className="v2">{a.portal}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-3 topic">
                                                <label className="v1"><i className="fas fa-map-marker-alt mr-2"></i><span>Estado</span></label>
                                                <span className="v2">{a.regionState}</span>
                                            </div>
                                            <div className="col-md-3 topic">
                                                <label className="v1"><i className="fas fa-city mr-2"></i><span>Município</span></label>
                                                <span className="v2">{a.regionCity}</span>
                                            </div>
                                            <div className="col-md-3 topic">
                                                <label className="v1"><i className="fas fa-calendar-day mr-2"></i><span>Data inicial</span></label>
                                                <span className="v2">{a.startedAt}</span>
                                            </div>
                                            <div className="col-md-3 topic">
                                                <label className="v1"><i className="fas fa-calendar-day mr-2"></i><span>Data final</span></label>
                                                <span className="v2">{a.finishedAt}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-3 topic">
                                                <label className="v1"><i className="fas fa-calendar-day mr-2"></i><span>Data da publicação</span></label>
                                                <span className="v2">{a.publishedAt}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 d-flex align-items-stretch">
                                    <div className="box-bg warn d-flex flex-column align-items-center justify-content-center">
                                        <div className="v1">
                                            <i className="fas fa-exclamation-triangle mr-2"></i>Comunicado
                                        </div>
                                        <div className="v2 text-center mt-3">Nenhum comunicado no momento</div>
                                    </div>
                                </div>
                                <div className="col-md-2 d-flex align-items-stretch">
                                    <div className="box-bg notes d-flex flex-column align-items-center justify-content-center">
                                        <div className='v1'>Sem anotações</div>
                                    </div>
                                </div>
                            </div>
                            <h5 className="p-0 mt-3">Objeto</h5>
                            <div dangerouslySetInnerHTML={{ __html: markAdditionals(a.search, markKeywords(a.search, a.object)) }} />
                            <div className="table-responsive mt-3">
                                <table className="table table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th>Grupo</th>
                                            <th>Itens</th>
                                            <th>Descrição</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {a.items.map((b, bi) => (
                                            <tr key={bi}>
                                                <td>{b.group}</td>
                                                <td>{b.qty}</td>
                                                <td dangerouslySetInnerHTML={{ __html: markAdditionals(a.search, markKeywords(a.search, b.description)) }} />
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <Link to='/'>Ver mais detalhes</Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

const mapStateToProps = ({ find: { searchAll } }) => ({
    myResults: searchAll.list,
});

const mapDispatchToProps = (dispatch) => ({
    methods: bindActionCreators(
        {
            callSearchAllGet
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchAll)
import PortalPage from "../containers/Portal/PortalPage"
import RequesterPage from "../containers/Requester/RequesterPage"

let IntegrationRoutes = [
    {
        path: 'integration/portal/*',
        container: PortalPage,
    },
    {
        path: 'integration/requester/*',
        container: RequesterPage,
    },
]

export default IntegrationRoutes
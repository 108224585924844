import { Routes, Route, useNavigate } from 'react-router-dom'
import { Provider } from "react-redux";
import storeAdmin from "./admin/store";
import storeOffice from "./user/store";
import AdminMiddleware from "./admin/middleware";
import OfficeMiddleware from "./user/middleware";
// import officeRoutes from './user/routes';
import AdminHeaderLogged from './admin/common/containers/HeaderLogged';
import AdminDashboard from './admin/modules/dashboard/containers/Dashboard';
import AdminLogin from './admin/modules/account/containers/Login';
import AdminSupportLogin from './admin/modules/support/containers/Login';
import AdminSupportCode from './admin/modules/support/containers/Code';
import UserHeaderLogged from './user/common/containers/HeaderLogged';
import UserDashboard from './user/modules/dashboard/containers/Dashboard';
import UserLogin from './user/modules/account/containers/Login';
import SearchAll from './user/modules/find/containers/SearchAll';

function App() {
  window.navigate = useNavigate()
  return (
    <Routes>
      {/* Routes Admin */}
      <Route path="/admin/support/code" exact element={
        <Provider store={storeAdmin}>
          <AdminMiddleware forceAuth={false} exact={true} header={null}>
            <AdminSupportCode />
          </AdminMiddleware>
        </Provider>} />
      <Route path="/admin/support/login" exact element={
        <Provider store={storeAdmin}>
          <AdminMiddleware forceAuth={false} exact={true} header={null}>
            <AdminSupportLogin />
          </AdminMiddleware>
        </Provider>} />
      <Route path="/admin/account/login" exact element={
        <Provider store={storeAdmin}>
          <AdminMiddleware forceAuth={false} exact={true} header={null}>
            <AdminLogin />
          </AdminMiddleware>
        </Provider>} />
      <Route path="/admin/*" index element={<Provider store={storeAdmin}><AdminMiddleware forceAuth={true} exact={true} header={<AdminHeaderLogged />}><AdminDashboard /></AdminMiddleware></Provider>} />

      {/* Routes User */}
      <Route path="/account/login" exact element={
        <Provider store={storeOffice}>
          <OfficeMiddleware forceAuth={false} exact={true} header={null}>
            <UserLogin />
          </OfficeMiddleware>
        </Provider>} />
      <Route path="/search/all" index element={<Provider store={storeOffice}><OfficeMiddleware forceAuth={true} exact={true} header={<UserHeaderLogged />}><SearchAll /></OfficeMiddleware></Provider>} />
      <Route path="/*" index element={<Provider store={storeOffice}><OfficeMiddleware forceAuth={true} exact={true} header={<UserHeaderLogged />}><UserDashboard /></OfficeMiddleware></Provider>} />
    </Routes>
  );
}

export default App
import { connect } from 'react-redux'
import { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { callPortalListGet } from '../../actions/portals'
import { useSearchParams } from 'react-router-dom'
import TableView from '../../../../../common/containers/TableView'

let PortalList = ({ setPageAttr, list, methods: { callPortalListGet } }) => {
    let [searchParams] = useSearchParams();
    
    const headers = [
        {
            type: 'info',
            name: 'code',
            align: 'left',
            label: 'Código'
        },
        {
            type: 'info',
            name: 'name',
            align: 'left',
            label: 'Nome'
        },
        {
            type: 'info',
            name: 'createdAt',
            align: 'right',
            label: 'Data de Implementação'
        }
    ]

    useEffect(() => {
        let pg = searchParams.get('pg')
        pg = pg ? pg : 1
        callPortalListGet({}, pg)
    }, [callPortalListGet, searchParams])

    useEffect(() => {
        setPageAttr({
            title: 'Portais',
            caption: 'Gestão dos metadados dos portais da integração',
            btns: [],
            tabs: [
                {
                    active: true,
                    to: '/admin/integration/portal',
                    label: 'Informações'
                }
            ]
        })
    }, [setPageAttr])

    return (
        <TableView headers={headers} rows={list.rows} total={list.total} pagination={list.pagination} paginationTo={'/admin/register/manager'} />
    )
}

const mapStateToProps = ({ integration: { portals: { list } } }) => ({
    list: list,
});

const mapDispatchToProps = (dispatch) => ({
    methods: bindActionCreators(
        {
            callPortalListGet,
        },
        dispatch
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(PortalList)
import Api from '../../../../axios'
import { callLoader } from '../../../../common/actions/app'

let ACTION_SEARCH_RESULT_LIST = {
    type: 'SEARCH_RESULT_LIST',
    payload: {
        rows: [],
        total: 0,
        pagination: [],
    },
};

let ACTION_SEARCH_WARNING_LIST = {
    type: 'SEARCH_WARNING_LIST',
    payload: {
        rows: [],
        total: 0,
        pagination: [],
    },
};

let callSearchResultGet = (filter = {}, pg = 1) => (dispatch) => {
    dispatch(callLoader(true))
    Api.get('/user/search/result?pg=' + pg).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        ACTION_SEARCH_RESULT_LIST.payload.rows = data.rows
        ACTION_SEARCH_RESULT_LIST.payload.total = data.total
        ACTION_SEARCH_RESULT_LIST.payload.pagination = data.pagination
        dispatch(ACTION_SEARCH_RESULT_LIST)
    })
}

let callSearchWarningGet = (filter = {}, pg = 1) => (dispatch) => {
    dispatch(callLoader(true))
    Api.get('/user/search/warning?pg=' + pg).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        ACTION_SEARCH_WARNING_LIST.payload.rows = data.rows
        ACTION_SEARCH_WARNING_LIST.payload.total = data.total
        ACTION_SEARCH_WARNING_LIST.payload.pagination = data.pagination
        dispatch(ACTION_SEARCH_WARNING_LIST)
    })
}

export { callSearchResultGet, callSearchWarningGet, ACTION_SEARCH_RESULT_LIST, ACTION_SEARCH_WARNING_LIST }
import CompanyPage from "../containers/Company/CompanyPage"
import ManagerPage from "../containers/Manager/ManagerPage"
import UserPage from "../containers/User/UserPage"

let RegisterRoutes = [
    {
        path: 'register/manager/*',
        container: ManagerPage,
    },
    {
        path: 'register/company/*',
        container: CompanyPage,
    },
    {
        path: 'register/user/*',
        container: UserPage,
    }
]

export default RegisterRoutes
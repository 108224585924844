import { toast } from "react-toastify";
import Api from '../../../../axios'
import { callLoader } from '../../../../common/actions/app'

let ACTION_REQUESTER_LIST = {
    type: 'REQUESTER_LIST',
    payload: {
        rows: [],
        total: 0,
        pagination: [],
    },
};

let ACTION_REQUESTER_VIEW = {
    type: 'REQUESTER_VIEW',
    payload: {
        code: '',
        name: '',
    },
};

let callRequesterListGet = (filter = {}, pg = 1) => (dispatch) => {
    dispatch(callLoader(true))
    Api.get('/admin/integration/requester?pg=' + pg).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        ACTION_REQUESTER_LIST.payload.rows = data.rows
        ACTION_REQUESTER_LIST.payload.total = data.total
        ACTION_REQUESTER_LIST.payload.pagination = data.pagination
        dispatch(ACTION_REQUESTER_LIST)
    })
}

let callRequesterViewGet = (id) => (dispatch) => {
    dispatch(callLoader(true))
    Api.get('/admin/integration/requester/' + id).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        ACTION_REQUESTER_VIEW.payload.code = data.code
        ACTION_REQUESTER_VIEW.payload.name = data.name
        dispatch(ACTION_REQUESTER_VIEW)
    })
}

let callRequesterPost = (data, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.post('/admin/integration/requester', data).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

let callRequesterPut = (id, data, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.put('/admin/integration/requester/' + id, data).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

let callRequesterClearView = () => (dispatch) => {
    ACTION_REQUESTER_VIEW.payload.code = ''
    ACTION_REQUESTER_VIEW.payload.name = ''
    dispatch(ACTION_REQUESTER_VIEW)
}

let callRequesterDelete = (id, success = () => { }) => (dispatch) => {
    dispatch(callLoader(true))
    Api.delete('/admin/integration/requester/' + id).then((data) => {
        dispatch(callLoader(false))
        if (!data)
            return

        toast.success(data.msg)
        success()
    })
}

export { callRequesterListGet, callRequesterViewGet, callRequesterPost, callRequesterPut, callRequesterDelete, callRequesterClearView, ACTION_REQUESTER_LIST, ACTION_REQUESTER_VIEW }